import * as actionTypes from "./actionTypes";
import axios from "axios";
import * as apiRoutes from "../../api-routes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, user) => {
  localStorage.setItem("token", JSON.stringify(token));
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user: user
  };
};

export const checkAuth = () => {
  return dispatch => {
    if (window.location.pathname == "/logout") {
      return;
    }
    let token = localStorage.getItem("token");
    if (!token) {
      dispatch(authLogout());
    } else {
      token = JSON.parse(token);
      const url = apiRoutes.USER;
      axios.defaults.headers.ACCEPT = "application/json";
      axios.defaults.headers.Authorization =
        token.token_type + " " + token.access_token;
      axios
        .get(url)
        .then(function(response) {
          dispatch(authSuccess(token, response.data));
        })
        .catch(function(error) {
          error.response
            ? dispatch(authFail(error.response.request.response))
            : dispatch(authFail(error));
          dispatch(authLogout());
        });
    }
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const authLogout = () => {
  localStorage.clear();
  console.log("clear");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    const urlLogin = apiRoutes.LOGIN;
    const urlUser = apiRoutes.USER;
    const secret = process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      type = process.env.REACT_APP_OAUTH_GRANT_TYPE,
      client_id = process.env.REACT_APP_OAUTH_CLIENT_ID;
    axios.defaults.headers.ACCEPT = "application/json";
    axios.defaults.headers.Content = "applicaiton/x-www-form-urlencoded";

    axios
      .post(urlLogin, {
        grant_type: type,
        client_id: client_id,
        client_secret: secret,
        username: email,
        password: password
      })
      .then(function(response) {
        let token = response.data;

        axios.defaults.headers.Authorization =
          token.token_type + " " + token.access_token;

        axios
          .get(urlUser)
          .then(function(response) {
            dispatch(authSuccess(token, response.data));
            // sessionStorage.setItem("jwtToken", response.data.token);
          })
          .catch(function(error) {
            error.response
              ? dispatch(authFail(error.response.request.response))
              : dispatch(authFail(error));
          });
        // dispatch(authSuccess(response.data.token, response.data.user));
        // sessionStorage.setItem("jwtToken", response.data.token);
      })
      .catch(function(error) {
        error.response
          ? dispatch(authFail(error.response.request.response))
          : dispatch(authFail(error));
      });
  };
};
